import PropTypes from 'prop-types';
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import _ from 'lodash';
import {
	paramKey, unitParamKey, getTagFields, bidderParams, cloneOrgValue
} from 'relevant-shared/prebid/bidParamUtils';
import { GenericTagField } from 'relevant-shared/objects/tagFieldBase';
import { stringToObject, objectToString } from 'relevant-shared/misc/funcJson';
import { PrebidDataObjects } from 'relevant-shared/prebid/prebidDataObjects';
import classes from '../../api/classes';
import styles from './styles.css';
import MiscUtils from '../../lib/miscUtils';
import { ensureLoaded } from '../../lib/objectStoreUtils';
import PopupSelector from '../PopupSelector';
import TextEditor from '../TagEditor/textEditor';
import ExpandSelector from '../ExpandSelector';
import FieldDataObject from '../TagEditor/fieldDataObject';
import { FormOf, CheckboxSign } from '../Wrappers';

const { UnitParams } = classes;

const isValid = (str) => {
	try {
		stringToObject(str);
	} catch (e) {
		return false;
	}
	return true;
};

class BidParamEdit extends React.Component {
	static invalidateInheritedPreviewValues() {
		BidParamEdit.applyGeneration = (BidParamEdit.applyGeneration || 0) + 1;
	}

	static notifyNodeUpdatedExternally(node) {
		if (node) {
			BidParamEdit.externallyUpdatedNodes[node.id] = Math.random();
		}
	}

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillUnmount() {
		const { autoApplyChanges } = this.props;
		if (autoApplyChanges && this.hasChanges) {
			this.applyChanges();
		}
	}

	applyChanges() {
		const {
			node, dstObj, pbjsConfig, onDone, dirtyBidParamSet,
		} = this.props;
		const { byObjectParams } = this;
		const pbjsConfigId = (pbjsConfig || {}).id;
		if (_.values(byObjectParams).find((p) => !isValid(p.customStr))) {
			return false;
		}
		_.forOwn(byObjectParams, ({ customStr, fieldObj }, sspId) => {
			const key = paramKey(node && node.id, sspId, pbjsConfigId);
			const existing = dstObj.bidParams.find((p) => unitParamKey(p) === key);
			const newParam = {
				...GenericTagField.toRawData(fieldObj),
				...stringToObject(customStr),
			};
			const empty = _.isEmpty(newParam);
			if (empty && existing) {
				if (existing.isNew) {
					_.pull(dstObj.bidParams, existing);
				} else {
					// Don't remove existing UnitParams object if it's stored in DB. The reason is that someone
					// might have changed something else concurrently and saved before us, and we don't want that
					// changes to get lost. So instead - keep it with empty .params
					existing.params = {};
				}
			} else if (!empty) {
				if (existing) {
					existing.params = newParam;
				} else {
					dstObj.bidParams.push(new UnitParams({
						unitId: node ? node.id : null,
						sspId,
						pbCfgId: pbjsConfigId,
						params: newParam,
					}));
				}
			}
		});
		if (node?.publisherNode?.refreshBidParams) {
			node.publisherNode.refreshBidParams();
		}
		onDone();
		this.hasChanges = false;
		if (dirtyBidParamSet) {
			dirtyBidParamSet.delete(this);
		}
		BidParamEdit.invalidateInheritedPreviewValues();
		return true;
	}

	render() {
		const {
			ssps, userIdModules, node, pbjsConfig, dstObj, checkbox, customLink, containerCls, elementContainerCls, okLabel,
			containerProps, includeDataFilterFn, form, dirtyBidParamSet, noEmptyExpandCustomParams, onCancel, canCancel,
		} = this.props;

		const sspsById = _.keyBy(ssps, 'id');
		const userIdModulesById = _.keyBy(userIdModules, 'id');
		const globalSettings = node ? node.publisherNode.globalSettings : this.props.globalSettings;
		// TODO: When is node.ssp not undefined? do we need an equivalent for userIdModules?
		const availSsps = node && node.ssp ? [node.ssp] : ssps.filter((ssp) => ssp.bidderName);

		const availDatas = _.map(PrebidDataObjects, (obj, id) => ({
			id,
			headerStyle: { fontWeight: 'bold' },
			...obj,
		})).filter((obj) => obj.availForNode(node, pbjsConfig, dstObj));

		const availByType = _.mapValues({
			data: availDatas,
			ssps: MiscUtils.alphaSorted(availSsps, 'name'),
			uids: MiscUtils.alphaSorted(userIdModules, 'name'),
		}, (arr) => (includeDataFilterFn ? arr.filter(includeDataFilterFn) : arr));

		const availOptions = [...availByType.data, ...availByType.ssps, ...availByType.uids];
		const firstSspListIndex = availByType.data.length;
		const firstUserIdModuleListIndex = firstSspListIndex + availByType.ssps.length;

		const isSingleOption = availOptions.length === 1;
		const pbjsConfigId = (pbjsConfig || {}).id;

		const bidParamsFor = (obj, systemId) => (obj.bidParams.find((p) => p.sspId === systemId) || {}).params || {};

		const nonFieldParentCustomParams = (system) => {
			if (!node) {
				const tagFields = getTagFields(system.id, { globalSettings, sspsById, userIdModulesById });
				const arr = [];
				if (dstObj !== globalSettings) {
					arr.push(bidParamsFor(globalSettings, system.id));
				}
				arr.push(system.defaultBidParams || {});
				return bidderParams(arr, tagFields, { onlyNonFields: true });
			}
			return node.bidderParams({
				sspId: system.id,
				pbjsConfigId,
				includeSelf: false,
				onlyNonFields: true,
			});
		};

		const individualParamObj = (sspId) => {
			if (!node) {
				return bidParamsFor(dstObj, sspId);
			}
			return node.individualBidderParams({ sspId, pbjsConfigId });
		};

		const individualCustomParams = (systemId) => {
			const tagFields = getTagFields(systemId, { globalSettings, sspsById, userIdModulesById });
			const params = individualParamObj(systemId);
			return _.omit(params, _.map(tagFields, 'name'));
		};

		const fieldParams = (systemId) => {
			const tagFields = getTagFields(systemId, { globalSettings, sspsById, userIdModulesById });
			if (!node) {
				const arr = [];
				if (dstObj !== globalSettings) {
					arr.push(bidParamsFor(globalSettings, systemId));
				}
				arr.push(individualParamObj(systemId));
				const res = bidderParams(arr, tagFields, { withMeta: true });
				return res;
			}
			return node.bidderParams({ sspId: systemId, pbjsConfigId, withMeta: true });
		};

		// Calling this function might be necessary after changes in other (ancestor) objects as we want to
		// change the disabled preview-values to the updated values
		const updateInheritedFields = () => {
			const mergeChanges = (dst, src) => {
				_.forOwn(dst, (v, k) => {
					if (!v.isOwn) {
						dst[k] = cloneOrgValue(src[k].orgValue) || src[k]; // ...|| src[k] shouldn't really be needed
						// to not switch back to an object where part of it (that is not overriden) has now invalid
						// preview values.
						delete v.prevOwn;
					} else if (v.type === 'Object' || v.type === 'ByObject') {
						mergeChanges(v.value, src[k].value);
					}
				});
			};
			_.forOwn(this.byObjectParams, ({ fieldObj }, sspId) => {
				const newObj = fieldParams(sspId);
				mergeChanges(fieldObj, newObj);
			});
			this.applyGeneration = BidParamEdit.applyGeneration;
		};

		const initObjects = async () => {
			await ensureLoaded();
			this.applyGeneration = BidParamEdit.applyGeneration;
			this.externalUpdateGeneration = BidParamEdit.externallyUpdatedNodes[node?.id];
			this.byObjectParams = _.mapValues(_.keyBy(availOptions, 'id'), (v, sspId) => ({
				customStr: objectToString(individualCustomParams(sspId), true),
				fieldObj: fieldParams(sspId),
			}));
		};

		const Container = containerCls;
		const ElementContainer = elementContainerCls;
		return (
			<Box display="flex">
				<Container
					{...containerProps}
					form={form}
					forms={form.formCollection()}
					title="Prebid parameters"
					size="md"
					customLink={customLink}
					okLabel={okLabel}
					fn={initObjects}
					canCancel={canCancel}
					allowErrorsIfCancel={canCancel}
					onCancel={onCancel}
					onApplyChanges={({ preventDefault }) => {
						if (!this.applyChanges()) {
							preventDefault();
						}
					}}
					content={(popupSelector) => {
						if (this.applyGeneration !== BidParamEdit.applyGeneration) {
							// Update inherited values as they might have changed. Reasons are that an ancestor's
							// bid parameters have changed or an ancestor have been swapped. The latter happens
							// for a placement's bid parameter when the placement type changes.
							updateInheritedFields();
						}
						if (this.externalUpdateGeneration !== BidParamEdit.externallyUpdatedNodes[node?.id]) {
							// Update everything as the bid-parameters for our node have been changed from outside.
							initObjects();
						}
						return (
							<div>
								{availOptions.map((obj, index) => {
									const inheritStr = objectToString(nonFieldParentCustomParams(obj), true);
									const objState = this.byObjectParams[obj.id];
									const { customStr, fieldObj } = objState;
									const checkChangeAllowed = (newExpand) => newExpand || isValid(customStr);
									const hasOwnCustom = _.some(customStr, (c) => !'{} \t\n\r'.includes(c));
									const hasOwnField = _.some(_.values(fieldObj), 'isOwn');
									const onFieldUpdate = () => {
										if (!this.hasChanges) {
											this.hasChanges = true;
											if (dirtyBidParamSet) {
												dirtyBidParamSet.add(this);
											}
										}
										popupSelector.update();
									};
									return (
										<Box mb={2} key={obj.id}>
											{ index === firstSspListIndex && (
												<Typography
													variant="h3"
													className={styles.systemTypeHeader}
												>
													SSPs
												</Typography>
											)}
											{ index === firstUserIdModuleListIndex && (
												<Typography
													variant="h3"
													className={styles.systemTypeHeader}
												>
													User ID modules
												</Typography>
											)}
											<ElementContainer
												form={form}
												forms={form.formCollection()}
												key={obj.id}
												title={(
													<div>
														<span style={obj.headerStyle}>
															{obj.name}
														</span>
														{(hasOwnCustom || hasOwnField) && <CheckboxSign />}
													</div>
												)}
												expanded={isSingleOption}
												checkChangeAllowed={checkChangeAllowed}
											>
												<FormOf
													model={fieldObj}
													formCollection={form.formCollection()}
													onFieldUpdate={onFieldUpdate}
													content={({ field, model }) => (
														<FieldDataObject
															model={model}
															field={field}
														/>
													)}
												/>
												{ !obj.excludeCustomParameters && (
													<ExpandSelector
														customHeader={hasOwnCustom && (
															<CheckboxSign style={{ top: -24, left: -30 }} />
														)}
														title="Custom Parameters"
														expanded={(_.isEmpty(fieldObj) && !noEmptyExpandCustomParams) || hasOwnCustom}
														checkChangeAllowed={checkChangeAllowed}
													>
														<Card>
															<CardContent>
																<Typography variant="h2">
																	Inherited parameters
																</Typography>
																<TextEditor
																	width="100%"
																	height={`${inheritStr.split('\n').length * 14}px`}
																	name={`${obj.id}_inherit`}
																	readOnly
																	showGutter={false}
																	value={inheritStr}
																/>
															</CardContent>
														</Card>
														<Card>
															<CardContent>
																<Typography variant="h2">
																	Parameters
																</Typography>
																<FormOf
																	model={objState}
																	formCollection={form.formCollection()}
																	onFieldUpdate={onFieldUpdate}
																	content={({ field }) => (
																		<TextEditor
																			width="100%"
																			height="170px"
																			{...(field('customStr'))}
																			satisfy={{ valid: isValid, message: 'Syntax error' }}
																		/>
																	)}
																/>
															</CardContent>
														</Card>
													</ExpandSelector>
												)}
											</ElementContainer>
										</Box>
									);
								})}
							</div>
						);
					}}
				/>
				{checkbox && (<CheckboxSign />)}
			</Box>
		);
	}
}

BidParamEdit.externallyUpdatedNodes = {};

BidParamEdit.propTypes = {
	form: PropTypes.object.isRequired,
	globalSettings: PropTypes.object,
	pbjsConfig: PropTypes.object,
	node: PropTypes.object,
	dstObj: PropTypes.object.isRequired,
	onDone: PropTypes.func,
	onCancel: PropTypes.func,
	canCancel: PropTypes.bool,
	ssps: PropTypes.array.isRequired,
	checkbox: PropTypes.bool,
	customLink: PropTypes.func,
	containerCls: PropTypes.func,
	containerProps: PropTypes.object,
	elementContainerCls: PropTypes.func,
	includeDataFilterFn: PropTypes.func,
	autoApplyChanges: PropTypes.bool,
	dirtyBidParamSet: PropTypes.instanceOf(Set),
	noEmptyExpandCustomParams: PropTypes.bool,
	okLabel: PropTypes.string,
	userIdModules: PropTypes.array,
};

BidParamEdit.defaultProps = {
	globalSettings: undefined,
	pbjsConfig: null,
	node: null,
	onDone: () => {},
	onCancel: () => {},
	canCancel: true,
	checkbox: false,
	customLink: undefined,
	containerCls: PopupSelector,
	containerProps: undefined,
	elementContainerCls: ExpandSelector,
	includeDataFilterFn: undefined,
	autoApplyChanges: false,
	dirtyBidParamSet: undefined,
	noEmptyExpandCustomParams: false,
	okLabel: undefined,
	userIdModules: [],
};

export default BidParamEdit;
