exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".container__src-components-StoredRequestTagInfo-styles__uEC1D {\n    padding: 16px 24px 8px 24px;\n    color: #5f6368;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/snack/temp/relevant-yield/relevant-frontend/src/components/StoredRequestTagInfo/styles.css"],"names":[],"mappings":"AAAA;IACI,4BAA2B;IAC3B,eAAc;CAClB","file":"styles.css","sourcesContent":[".container {\n    padding: 16px 24px 8px 24px;\n    color: #5f6368;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "container__src-components-StoredRequestTagInfo-styles__uEC1D"
};