import PropTypes from 'prop-types';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MuiCheckbox from '@mui/material/Checkbox';
import { getFormPropsFor } from '../../containers/Form';

class Checkbox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this._change = this.change.bind(this);
	}

	change(ev) {
		const { onChange, name, value } = this.props;
		onChange({ target: { name, value: !value }, orgEv: ev }, this.props);
	}

	render() {
		const {
			name, label, disabled, style, labelPosition, color, allowIndeterminate,
			margin, fullWidth, textFieldAlign, help,
		} = this.props;
		const { value } = getFormPropsFor(this);
		const indeterminate = value === null;
		return (
			<FormControl margin={margin} fullWidth={fullWidth}>
				<FormControlLabel
					control={(
						<MuiCheckbox
							name={name}
							checked={Boolean(value)}
							onChange={this._change}
							color={color}
							indeterminate={allowIndeterminate && indeterminate}
						/>
					)}
					label={label}
					labelPlacement={labelPosition}
					disabled={disabled}
					style={{ ...(textFieldAlign ? { marginTop: 8 } : null), ...style }}
				/>
				{!!help && (
					<FormHelperText>
						{help}
					</FormHelperText>
				)}
			</FormControl>
		);
	}
}

Checkbox.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOf([true, false, null]),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	labelPosition: PropTypes.oneOf(['start', 'end']),
	color: PropTypes.oneOf(['primary', 'secondary', 'default']),
	allowIndeterminate: PropTypes.bool,
	margin: PropTypes.oneOf(['none', 'dense', 'normal']),
	fullWidth: PropTypes.bool,
	textFieldAlign: PropTypes.bool,
	help: PropTypes.string,
};

Checkbox.defaultProps = {
	disabled: false,
	labelPosition: 'end',
	color: 'default',
	allowIndeterminate: false,
	margin: undefined,
	fullWidth: false,
	textFieldAlign: false,
	label: '',
	help: undefined,
};

export default Checkbox;
