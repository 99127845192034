/* eslint react/prop-types: 0 */
/* eslint jsx-a11y/iframe-has-title: 0 */
import React, { createContext, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useQueryClient } from '@tanstack/react-query';
import Form from '../../containers/Form';
import OperationWrapper from '../OperationWrapper';
import SystemData from '../../lib/systemData';
import ReactUtils from '../../lib/reactUtils';
import PopupSelector from '../PopupSelector';
import { stores } from '../../stores';

const withStyle = (props, defStyle) => {
	const style = { ...defStyle, ...props.style || {} };
	return { ...props, style };
};

const styledDiv = (props, style) => <div {...withStyle(props, style)}>{props.children}</div>;

const PageContentWrapperDiv = (props) => styledDiv(props, { paddingBottom: '100px' });

function CenterContent({ children, vertical, horizontal }) {
	return (
		<div style={{ position: 'relative', width: '100%', height: '100%' }}>
			<div
				style={{
					width: horizontal ? 'auto' : '100%',
					height: vertical ? 'auto' : '100%',
					position: 'absolute',
					margin: 0,
					top: vertical ? '50%' : 'auto',
					left: horizontal ? '50%' : 'auto',
					transform: `translate(${horizontal ? '-50%' : '0'}, ${vertical ? '-50%' : '0'})`,
				}}
			>
				{children}
			</div>
		</div>
	);
}

function HtmlContainer(props) {
	return (
		<iframe
			style={{
				...props.style, width: '100%', height: '100%', border: 0,
			}}
			scrolling="no"
			src="about:blank"
			ref={(ifr) => {
				if (!ifr) {
					return;
				}
				const win = ifr.contentWindow;
				const doc = win.document;
				const resize = () => {
					let needed = 0;
					[].slice.call(doc.body.children).forEach((child) => {
						needed = Math.max(needed, child.getBoundingClientRect().bottom);
					});
					const newHeight = `${needed}px`;
					if (ifr.style.height !== newHeight) {
						ifr.style.height = newHeight;
					}
				};
				const html = `
 				<!DOCTYPE html>
            	<html style='width: 100%; height: 100%;'>
            		<head>
            			<meta charset='UTF-8'/>
		            </head>
            		<body style='margin:0px; border:0px; padding:0px; width: 100%; height: 100%;'>
            		${props.html}
            		<script>callback()</script>
		            </body>
            	</html>`;
				doc.open();
				win.callback = () => {
					if (props.noResizing) {
						return;
					}
					win.addEventListener('load', resize);
					win.addEventListener('resize', resize);
					resize();
				};
				doc.write(html);
				doc.close();
			}}
		/>
	);
}

function PageContentWrapper(props) {
	return (
		<PageContentWrapperDiv>
			<Container maxWidth={props.maxWidth}>
				{props.children}
			</Container>
		</PageContentWrapperDiv>
	);
}

class SimpleContainer extends React.Component {
	render() {
		return (
			<OperationWrapper fn={async (op) => {
				const data = await this.props.init(op);
				this.setState({ ...data });
			}}
			>
				<Box sx={{ pt: this.props.counterGridNegativeMargin ? 0 : 3 }}>
					<Grid container spacing={3} justifyContent={this.props.justifyContent}>
						{this.state && React.createElement(this.props.component, this.state)}
					</Grid>
				</Box>
			</OperationWrapper>
		);
	}
}
SimpleContainer.propTypes = {
	component: PropTypes.elementType.isRequired,
	init: PropTypes.func,
	counterGridNegativeMargin: PropTypes.bool,
};

SimpleContainer.defaultProps = {
	init: () => ({}),
	counterGridNegativeMargin: false,
};

function SimpleFormContainer(props) {
	return <SimpleContainer {...props} component={Form(props.component)} />;
}

const withUpdate = (type) => {
	Object.assign(type.prototype, {
		update(fn) {
			if (fn) {
				fn();
			}
			this.forceUpdate();
		},
		async updateAsync(fn) {
			if (fn) {
				await fn();
			}
			this.forceUpdate();
		},
	});
};

const wrapFnComponent = (fn) => {
	@withUpdate
	class Container extends React.Component {
		render() {
			return fn(this.props, this);
		}
	}

	return Container;
};

class FormOf extends React.Component {
	constructor(props) {
		super(props);
		const wrappedContent = wrapFnComponent((...args) => this.props.content(...args));
		this.formComponent = Form(wrappedContent);
	}

	render() {
		const FormComponent = this.formComponent;
		return <FormComponent {...this.props} />;
	}
}
@withUpdate
class Scope extends React.Component {
	constructor(props) {
		super(props);
		this.state = _.omit(props, ['content']);
		this.fld = ReactUtils.fld(this);
	}

	reset() {
		this.setState(_.omit(this.props, ['content']));
	}

	render() {
		const { content, setVal } = this.props;
		let oldVal;
		if (setVal) {
			const { obj, name, value } = setVal;
			oldVal = obj[name];
			obj[name] = value;
		}
		const res = content(this, this.state);
		if (setVal) {
			const { obj, name } = setVal;
			obj[name] = oldVal;
		}
		return res;
	}
}

function PopupForm(props) {
	const { content, getModel, onApplyChanges } = props;
	return (
		<Scope
			content={(scope, { obj }) => (
				<FormOf
					model={obj || {}}
					content={(p) => (
						<PopupSelector
							allowErrorsIfCancel
							{...props}
							form={p.form}
							content={!content ? null : (popup, opResult) => content({ ...p, popup, opResult })}
							onStateUpdate={async ({ expanded }) => {
								if (expanded) {
									p.form.resetErrors();
									scope.setState({ obj: getModel() });
								}
							}}
							onApplyChanges={(params) => (
								onApplyChanges({ ...params, ...p })
							)}
						/>
					)}
				/>
			)}
		/>
	);
}

function IfCond(props) {
	const { cond, replacer } = props;
	if (cond) {
		return props.children;
	} if (replacer) {
		return replacer();
	}
	return null;
}

function IfHasAudience(props) {
	return (
		<IfCond cond={!SystemData.genericData.disableAudience}>
			{props.children}
		</IfCond>
	);
}

function IfHasHbManager(props) {
	return (
		<IfCond cond={SystemData.genericData.hbmEnabled}>
			{props.children}
		</IfCond>
	);
}

function IfHasHbAnalytics(props) {
	return (
		<IfCond cond={SystemData.genericData.hbaEnabled}>
			{props.children}
		</IfCond>
	);
}

function IfDemoMode(props) {
	return (
		<IfCond cond={SystemData.genericData.inDemoMode}>
			{props.children}
		</IfCond>
	);
}

function IfSuperAdmin(props) {
	return (
		<IfCond cond={stores.identity.isSuperAdministrator()}>
			{props.children}
		</IfCond>
	);
}

function DemoHide(props) {
	if (SystemData.genericData.OBSCURE_NAMING_ENABLED && (!('cond' in props) || props.cond)) {
		return props.replacer ? props.replacer() : (<i>Not visible in demo mode</i>);
	}
	return (<div>{props.children}</div>);
}

function CheckboxSign({ style, content }) {
	return (
		<div style={{ display: 'inline', position: 'relative' }}>
			<div
				style={{
					color: 'green',
					display: 'inline',
					position: 'absolute',
					pointerEvents: 'none',
					fontSize: 30,
					top: -12,
					...style,
				}}
			>
				{content || '✓'}
			</div>
		</div>
	);
}

function PopupBox({
	style, children, opener, paperProps, paperStyle, content, onOpenChange
}) {
	const [open, setOpenInternal] = useState(false);
	const setOpen = (newOpen) => {
		onOpenChange?.(newOpen);
		setOpenInternal(newOpen);
	};
	const params = {
		openFn: () => setOpen(true),
		closeFn: () => setOpen(false),
	};
	return (
		<>
			{opener && opener(params)}
			{open && (
				<div style={{ position: 'relative' }}>
					<div
						style={{
							position: 'absolute',
							zIndex: 10,
							minWidth: 300,
							background: 'white',
							...style,
						}}
					>
						<Paper elevation={5} style={{ padding: 5, ...paperStyle }} {...paperProps}>
							<ClickAwayListener onClickAway={() => setOpen(false)}>
								<div>
									{content ? content(params) : children}
								</div>
							</ClickAwayListener>
						</Paper>
					</div>
				</div>
			)}
		</>
	);
}

function SimpleAlert({
	severity, title, content, action,
}) {
	return (
		<Alert
			severity={severity || 'info'}
			sx={{ mt: 1, '& .MuiAlert-message': { width: '100%' } }}
		>
			<Box style={{ display: 'flex' }}>
				<Box style={{ flexGrow: 1 }}>
					<AlertTitle>{title}</AlertTitle>
					{content}
				</Box>
				{action}
			</Box>
		</Alert>
	);
}

function QueryInvalidate({ query }) {
	const queryClient = useQueryClient();
	queryClient.invalidateQueries(query);
	return undefined;
}

/** Helper-component for context providers/consumers (as they tend to be a bit line-heavy with class-components)
 * Example:
 * <ContextWrapper name="test" transform={() => 0}>
 *     ...
 *     <ContextWrapper
 *     		name="test"
 *     		transform={(prev) => prev + 1}
 *     		content={(val) => (<div>{val}</div>) //"1"}
 *     />
 * <ContextWrapper>
 */
function ContextWrapper({
	name, transform, children, content, noClone,
}) {
	ContextWrapper.ctxByName = ContextWrapper.ctxByName || new Map();
	let Context = ContextWrapper.ctxByName.get(name);
	if (!Context) {
		Context = createContext();
		ContextWrapper.ctxByName.set(name, Context);
	}
	return (
		<Context.Consumer>
			{(value) => {
				const nextValue = transform ? transform(noClone ? value : _.cloneDeep(value)) : value;
				return (
					<Context.Provider value={nextValue}>
						{content && content(nextValue)}
						{children}
					</Context.Provider>
				);
			}}
		</Context.Consumer>
	);
}

export {
	CenterContent,
	PageContentWrapperDiv,
	PageContentWrapper,
	SimpleContainer,
	HtmlContainer,
	SimpleFormContainer,
	wrapFnComponent,
	DemoHide,
	IfHasAudience,
	IfHasHbAnalytics,
	IfHasHbManager,
	IfCond,
	FormOf,
	withUpdate,
	Scope,
	CheckboxSign,
	PopupBox,
	PopupForm,
	SimpleAlert,
	IfDemoMode,
	QueryInvalidate,
	IfSuperAdmin,
	ContextWrapper,
};
