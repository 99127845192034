/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import moment from 'moment/moment';
import { ActionButton } from '../ActionButton/ActionButton';
import { SimpleAlert} from '../Wrappers';

export default function reportBlockingReason({ reportData, onUpdateState }) {
	const { dataTooOld, dataTooOldLimit } = reportData;
	if (dataTooOld) {
		return (
			<SimpleAlert
				title="Show report containing old data?"
				content={(
					<>
						This report contains data older than
						<b> {moment.utc(dataTooOldLimit).format('YYYY-MM-DD')}</b>.
						To load it, press the button to the right.<br />
					</>
				)}
				action={(
					<ActionButton
						label="Load report"
						variant="outlined"
						onClick={() => onUpdateState({ warnOldData: false }, true)}
					/>
				)}
			/>
		);
	} else {
		return null;
	}
}
