exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".indent__src-components-TreeSelect-styles__tyv_4 {\n    padding-left: 40px !important;\n}\n", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/snack/temp/relevant-yield/relevant-frontend/src/components/TreeSelect/styles.css"],"names":[],"mappings":"AAAA;IACI,8BAA6B;CACjC","file":"styles.css","sourcesContent":[".indent {\n    padding-left: 40px !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"indent": "indent__src-components-TreeSelect-styles__tyv_4"
};