import PropTypes from 'prop-types';
import React from 'react';
import { Box, Grid } from '@mui/material';
import ExpandSelector from '../ExpandSelector';
import Form from '../../containers/Form';
import FieldDataObject from './fieldDataObject';

function ObjectData(props) {
	const {
		title, expand, field, addDisabler, value, alwaysOwn, inDisabled,
	} = props;
	// TODO: a lot of crazy inline styling in here to force the click-area in the collapse header on right place
	// Let's revisit when it breaks
	return (
		<ExpandSelector
			title={!!title && (
				<Box sx={{ width: '100%', textAlign: 'end' }}>
					<Box
						sx={{
							mr: 1,
							fontSize: 'small',
							fontStyle: 'italic',
							fontWeight: 400,
							position: 'relative',
							top: ({ spacing }) => spacing(1),
						}}
					>
						{title}
					</Box>
				</Box>
			)}
			expanded={expand}
			form={Form.fromField(field)}
			paperProps={{ sx: { mt: -6 } }}
			headerProps={{
				sx: {
					ml: 4,
					pb: 1,
					position: 'relative',
					height: ({ spacing }) => spacing(4),
				},
			}}
			collapseProps={{
				styles: {
					buttonDiv: {
						position: 'absolute',
						top: -16,
						bottom: -16,
						left: 0,
						right: 0,
					},
				},
			}}
		>
			<Grid
				container
				spacing={3}
				sx={{
					opacity: addDisabler ? 0.5 : 1,
					ml: '10px',
					mt: 2,
					width: '100%',
				}}
			>
				<FieldDataObject
					model={value.value}
					field={(path) => field(`value.${path}`)}
					alwaysOwn={alwaysOwn}
					inDisabled={inDisabled}
				/>
			</Grid>
		</ExpandSelector>
	);
}

ObjectData.propTypes = {
	title: PropTypes.node,
	expand: PropTypes.bool,
	field: PropTypes.func.isRequired,
	addDisabler: PropTypes.bool,
	value: PropTypes.object.isRequired,
	alwaysOwn: PropTypes.bool,
	inDisabled: PropTypes.bool,
};

ObjectData.defaultProps = {
	title: undefined,
	expand: false,
	addDisabler: PropTypes.bool,
	alwaysOwn: false,
	inDisabled: false,
};

export default ObjectData;
