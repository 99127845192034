const { fields } = require('./programmaticTagBaseFields');

const uiGroup = 'Built in settings applied on first call to relevantDigital.loadPrebid(...)';
const optimizationUiGroup = 'Auto-optimization settings';

const DEFAULT_BADGE = `
<div style="position: fixed; bottom: -2px;left: -2px; z-index: 1000000; border-color: white;border-style: solid;border-radius: 4px;border-width: 1px;background: #02a729;padding: 2px 7px;font-family: sans-serif;font-size: small;">
    <a href="{{link}}" target="{{target}}" style="text-decoration: none;">
        <span style="color: white;">
            {{infoText}}
        </span>
    </a>
</div>
`.trim();

module.exports = [
	{
		type: 'JavaScript',
		description: 'Custom Config JavaScript code',
		name: 'rlvCfgJs',
		uiGroup,
	},
	{
		type: 'Object',
		name: 'rlvTimeouts',
		description: 'Timeout fields to override from programmatic tag',
		fields: fields.filter((fld) => fld.isTimeoutField).map((fld) => ({
			...fld,
			defaultValue: null,
		})),
		uiGroup,
	},
	{
		type: 'Boolean',
		description: 'Enable bid caching',
		name: 'rlvBidCache',
		uiGroup,
	},
	{
		type: 'Object',
		description: 'Ad transparency',
		name: 'rlvTransparency',
		fields: [
			{
				type: 'Boolean',
				name: 'badge',
				description: 'Show ad information badge',
			},
			{
				type: 'Object',
				description: 'UI settings',
				name: 'uiSettings',
				fields: [
					{
						type: 'HTML',
						name: 'badgeHtml',
						description: 'HTML for ad information badge',
						defaultValue: DEFAULT_BADGE,
					},
					{
						type: 'String',
						name: 'infoText',
						description: 'Text in badge',
						isRequired: true,
						defaultValue: 'Ad info',
					},
					{
						type: 'String',
						name: 'target',
						description: 'Info window open behavior (target)',
						defaultValue: '_blank',
						isRequired: true,
						hasOptions: true,
						options: [
							['_blank', 'Open in new tab (_blank)'],
							['_self', 'Open inside ad (_self)'],
							['_top', 'Open in current tab (_top)'],
							['_parent', 'Open in parent frame (_parent)'],
						].map(([name, label]) => ({ name, label })),
					},
					{
						type: 'String',
						name: 'logoUrl',
						description: 'Override logo URL on info page (replaces Relevant logo if set)',
					},
					{
						type: 'String',
						name: 'link',
						description: 'Ad information page base URL',
						isRequired: true,
						defaultValue: 'https://cdn.relevant-digital.com/transparency/adInfo.html?settings=',
						// defaultValue: 'https://dev-api.relevant-digital.com/html/adInfo.html?settings=',
					},
				],
			},
			{
				type: 'Boolean',
				name: 'dsa',
				description: 'Enable sending DSA data',
			},
			{
				type: 'String',
				name: 'dsarequired',
				description: 'DSA required',
				defaultValue: '1',
				hasOptions: true,
				isRequired: true,
				options: [
					['0', 'Not required'],
					['1', 'Supported, bid responses with or without DSA object will be accepted'],
					['2', 'Required, bid responses without DSA object will not be accepted'],
					['3', 'Required, bid responses without DSA object will not be accepted, Publisher is an Online Platform'],
				].map(([name, label]) => ({ name, label })),
			},
			{
				type: 'Boolean',
				name: 'enforceDsa',
				description: 'Enforce DSA required by rejecting bids',
			},
			{
				type: 'String',
				name: 'datatopub',
				description: 'Data to publisher',
				defaultValue: '2',
				hasOptions: true,
				isRequired: true,
				options: [
					['0', 'Do not send transparency data'],
					['1', 'Optional to send transparency data'],
					['2', 'Send transparency data'],
				].map(([name, label]) => ({ name, label })),
			},
			{
				type: 'Boolean',
				name: 'letAdRender',
				description: 'Don\'t show own information badge if bid indicates "adrender=1"',
			},
			{
				type: 'Array',
				description: 'DSA Transparency objects to send',
				name: 'transparency',
				fields: [
					{
						type: 'String',
						name: 'domain',
						description: 'Domain',
						isRequired: true,
					},
					...[[1, 'Profiling'], [2, 'Basic advertising'], [3, 'Precise geolocation']].map(([nr, desc]) => ({
						type: 'Boolean',
						name: `param${nr}`,
						description: desc,
					})),
				],
			},
		],
	},
	{
		type: 'Object',
		description: 'GAM Lazy Load Settings',
		name: 'rlvGamLazyLoad',
		fields: [
			{
				type: 'Boolean',
				name: 'enabled',
				defaultValue: false,
				description: 'Enable lazy loading',
			},
			{
				type: 'Number',
				name: 'fetchMarginPercent',
				defaultValue: null,
				description: 'Fetch margin in percent',
			},
			{
				type: 'Number',
				name: 'renderMarginPercent',
				defaultValue: null,
				description: 'Render margin in percent',
			},
			{
				type: 'Number',
				name: 'mobileScaling',
				description: 'Mobile multiplier',
				defaultValue: null,
			},
		],
	},
	{
		type: 'Object',
		description: 'Price floor module enforcements',
		name: 'rlvFloorEnforce',
		omitWhenDefault: true,
		fields: [
			{
				type: 'Boolean',
				name: 'enforceJS',
				defaultValue: true,
				description: 'Let Prebid.JS enforce price floors by reject bids below it',
				omitWhenDefault: true,
			},
			{
				type: 'Boolean',
				name: 'floorDeals',
				description: 'Enforce price floors also for deals',
				omitWhenDefault: true,
			},
		],
	},
	{
		type: 'Number',
		description: '% of users where selected auto-optimization settings should be applied',
		name: 'rlvOptPerc',
		defaultValue: 90,
		props: { between: { low: 0, high: 100 } },
		uiGroup: optimizationUiGroup,
		optiField: true,
		isRequired: true,
	},
	{
		type: 'Number',
		description: '% of auto-optimized users used to find new optimal values',
		name: 'rlvBenchPerc',
		defaultValue: 10,
		props: { between: { low: 0, high: 100 } },
		uiGroup: optimizationUiGroup,
		optiField: true,
		isRequired: true,
	},
	{
		type: 'Number',
		description: 'Delay auctions at most this number of milliseconds when awaiting optimization data',
		name: 'rlvOptWait',
		uiGroup: optimizationUiGroup,
		optiField: true,
		isRequired: true,
	},
	{
		type: 'Number',
		description: 'Never delay auctions when optimization data is fresher than this number of minutes',
		name: 'rlvOptTimeoutMinutes',
		defaultValue: 1440,
		uiGroup: optimizationUiGroup,
		optiField: true,
		isRequired: true,
	},
	{
		type: 'Boolean',
		description: 'Add "Optimization freshness" + "Optimization group" HBA dimensions',
		name: 'rlvOptDebugDims',
		uiGroup: optimizationUiGroup,
		optiField: true,
		omitWhenDefault: true,
	},
];
