exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".spinner__src-components-SimpleOperationWrapper-styles__THb2Y {\n\tposition: absolute;\n\t/* - 50px because thats half of 100px (spinner width and height). \n\tCould easily be changed in the future to a more generic solution with variable size. */ \n\tleft: calc(50% - 50px);\n\ttop: calc(50% - 50px);\n\tz-index: 10000;\n}\n\n.hiddenSpinner__src-components-SimpleOperationWrapper-styles__2w21J {\n\tdisplay: none;\n}\n\n.disabledContent__src-components-SimpleOperationWrapper-styles__3OHoH {\n\tpointer-events: none;\n\topacity: 0.5;\n\twidth: 100%;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/snack/temp/relevant-yield/relevant-frontend/src/components/SimpleOperationWrapper/styles.css"],"names":[],"mappings":"AAAA;CACC,mBAAkB;CAClB;wFACsF;CACtF,uBAAsB;CACtB,sBAAqB;CACrB,eAAc;CACf;;AAEA;CACC,cAAa;CACd;;AAEA;CACC,qBAAoB;CACpB,aAAY;CACZ,YAAW;CACZ","file":"styles.css","sourcesContent":[".spinner {\n\tposition: absolute;\n\t/* - 50px because thats half of 100px (spinner width and height). \n\tCould easily be changed in the future to a more generic solution with variable size. */ \n\tleft: calc(50% - 50px);\n\ttop: calc(50% - 50px);\n\tz-index: 10000;\n}\n\n.hiddenSpinner {\n\tdisplay: none;\n}\n\n.disabledContent {\n\tpointer-events: none;\n\topacity: 0.5;\n\twidth: 100%;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"spinner": "spinner__src-components-SimpleOperationWrapper-styles__THb2Y",
	"hiddenSpinner": "hiddenSpinner__src-components-SimpleOperationWrapper-styles__2w21J",
	"disabledContent": "disabledContent__src-components-SimpleOperationWrapper-styles__3OHoH"
};