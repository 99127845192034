exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".pageContentWrapper__src-pages-AdministratorJobLogs-styles__2avF7 {\n    padding-bottom: 100px;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/snack/temp/relevant-yield/relevant-frontend/src/pages/AdministratorJobLogs/styles.css"],"names":[],"mappings":"AAAA;IACI,sBAAqB;CACzB","file":"styles.css","sourcesContent":[".pageContentWrapper {\n    padding-bottom: 100px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"pageContentWrapper": "pageContentWrapper__src-pages-AdministratorJobLogs-styles__2avF7"
};