const { CURRENCIES } = require('../constants');

const CURRENCY_OPTIONS = Object.entries(CURRENCIES).map(([code, { name }]) => ({
	name: code,
	label: `${name} (${code})`,
}));

const PROGRAMMATIC_TAG_BASE_FIELDS = {
	fields: [
		{
			// Value is ObjectId of a PrebidBuildConfiguration
			type: 'PrebidBuild',
			description: 'Prebid build',
			defaultValue: '',
			isContainer: false,
			name: 'prebidBuild',
		},
		{
			type: 'Number',
			fields: [],
			description: 'Prebid.JS timeout milliseconds',
			defaultValue: 1000,
			name: 'pbjsTimeout',
			isTimeoutField: true,
		},
		{
			type: 'Number',
			fields: [],
			description: 'Prebid.JS failsafe timeout milliseconds',
			defaultValue: 2000,
			name: 'pbjsFailsafeTimeout',
			isTimeoutField: true,
		},
		{
			type: 'Boolean',
			fields: [],
			description: 'Enable analytics collection',
			defaultValue: true,
			name: 'enableAnalytics',
		},
		{
			type: 'String',
			fields: [],
			description: 'Analytics URL',
			defaultValue: '',
			name: 'analyticsURL',
		},
		{
			type: 'String',
			fields: [],
			description: 'Prebid server URL',
			defaultValue: '',
			name: 'pbsUrl',
		},
		{
			type: 'String',
			fields: [],
			description: 'Prebid.JS global variable name (only used when a Prebid build is not selected)',
			defaultValue: 'pbjs',
			name: 'pbjsName',
		},
		{
			type: 'Boolean',
			description: 'Report Prebid Configuration as AdServer key-value.',
			name: 'reportPbCfg',
			defaultValue: false,
		},
		{
			type: 'JavaScript',
			fields: [],
			description: 'Custom Global JavaScript code',
			defaultValue: '',
			name: 'globalJs',
		},
		{
			type: 'JavaScript',
			fields: [],
			description: 'Custom Publisher JavaScript code',
			defaultValue: '',
			name: 'publisherJs',
		},
		{
			type: 'JavaScript',
			fields: [],
			description: 'Custom Site JavaScript code',
			defaultValue: '',
			name: 'siteJs',
		},
		{
			type: 'String',
			fields: [],
			description: 'Convert to currency in HBA reports',
			defaultValue: null,
			hasOptions: true,
			options: CURRENCY_OPTIONS,
			name: 'convertToCurrency',
		},
		{
			type: 'Number',
			fields: [],
			description: 'Prebid server round trip buffer (ms)',
			defaultValue: 200,
			hasOptions: false,
			name: 'pbsRoundTripBufferMs',
			isTimeoutField: true,
		},
		{
			type: 'Number',
			fields: [],
			description: 'Google MCM Child network ID (will be injected in ad units paths if non-zero)',
			hasOptions: false,
			name: 'mcmChildNwid',
		},
		{
			type: 'String',
			description: 'Price granularity',
			defaultValue: 'medium',
			hasOptions: true,
			name: 'priceGranularity',
			options: [
				{
					name: 'low',
					label: 'Low',
				},
				{
					name: 'medium',
					label: 'Medium',
				},
				{
					name: 'high',
					label: 'High',
				},
				{
					name: 'auto',
					label: 'Auto',
				},
				{
					name: 'dense',
					label: 'Dense',
				},
				{
					name: 'custom',
					label: 'Custom',
				},
			],
		},
		{
			type: 'Array',
			description: 'Custom price granularity ranges',
			defaultValue: [],
			name: 'customPriceGranularityRanges',
			fields: [
				{
					type: 'Number',
					name: 'increment',
					description: 'Increment',
					isRequired: true,
				},
				{
					type: 'Number',
					name: 'max',
					description: 'Max',
					isRequired: true,
				},
			],
		},
		{
			type: 'Number',
			name: 'customPriceGranularityPrecision',
			description: 'Custom price granularity precision',
			defaultValue: 2,
		},
		{
			type: 'String',
			name: 'adServerCurrency',
			description: 'Ad server currency',
			defaultValue: null,
			hasOptions: true,
			options: CURRENCY_OPTIONS,
		},
		{
			type: 'Boolean',
			fields: [],
			description: 'Include exchange rates (when \'Ad server currency\' is set)',
			defaultValue: false,
			name: 'includeRates',
		},
		{
			type: 'Number',
			name: 'geoWaitMs',
			description: 'Milliseconds to wait for geo information',
			defaultValue: 500,
		},
		{
			type: 'Boolean',
			description: 'Use local storage for optimization data (highly recommended)',
			name: 'optUseStorage',
			defaultValue: true,
		},
		{
			type: 'Number',
			description: 'Never reload optimization-settings when cached age is less than (seconds)',
			name: 'optMinReloadSec',
			isRequired: true,
			defaultValue: 300,
		},
		{
			type: 'Object',
			description: 'App / AMP / Adapter specific settings',
			name: 'storedReq',
			fields: [
				{
					type: 'Boolean',
					name: 'includebidderkeys',
					description: 'Send all bids to adserver',
				},
				{
					type: 'Boolean',
					name: 'preferdeals',
					description: 'Prefer deals (select deals even when CPM is not highest)',
				},
				{
					type: 'String',
					name: 'convertBidFloorCur',
					description: 'Convert bid floor into currency',
					defaultValue: null,
					hasOptions: true,
					options: CURRENCY_OPTIONS,
				},
				{
					type: 'Number',
					name: 'tmax',
					description: 'Prebid Server tmax/timeout value',
					defaultValue: null,
				},
				{
					type: 'Boolean',
					name: 'addNetworkBidAdjustment',
					description: 'Adjust bids according to publisher\'s OpenRTB cut (%)',
				},
			],
		},
		{
			type: 'Boolean',
			fields: [],
			description: 'Externally load Prebid.js',
			defaultValue: false,
			name: 'loadPbjs',
		},
		{
			type: 'String',
			fields: [],
			description: 'External Prebid.JS URL',
			defaultValue: 'https://apps-cdn.relevant-digital.com/static/prebid_releases/prebid_current.js',
			name: 'pbjsURL',
		},
	],
};

module.exports = PROGRAMMATIC_TAG_BASE_FIELDS;
