exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".card__src-containers-ProgrammaticCommonSettings-styles__3kAjt {\n\tmargin-bottom: 16px;\n}\n\n.cardHeader__src-containers-ProgrammaticCommonSettings-styles__p8znQ {\n\tdisplay: flex;\n}\n\n.cardTitle__src-containers-ProgrammaticCommonSettings-styles__25Z3K {\n\tmargin: 3px 0px 0px 10px;\n}", "", {"version":3,"sources":["/home/ubuntu/custom_apps_instances/snack/temp/relevant-yield/relevant-frontend/src/containers/ProgrammaticCommonSettings/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAmB;CACpB;;AAEA;CACC,cAAa;CACd;;AAEA;CACC,yBAAwB;CACzB","file":"styles.css","sourcesContent":[".card {\n\tmargin-bottom: 16px;\n}\n\n.cardHeader {\n\tdisplay: flex;\n}\n\n.cardTitle {\n\tmargin: 3px 0px 0px 10px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"card": "card__src-containers-ProgrammaticCommonSettings-styles__3kAjt",
	"cardHeader": "cardHeader__src-containers-ProgrammaticCommonSettings-styles__p8znQ",
	"cardTitle": "cardTitle__src-containers-ProgrammaticCommonSettings-styles__25Z3K"
};