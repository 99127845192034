const _ = require('lodash');
const TypeCustomizer = require('./typeCustomizer');
const Constants = require('../constants');
const EnvReportInterface = require('../envReportInterface');
const DateUtils = require('../../misc/dateUtils');
const DynamicDateRanges = require('../dynamicDateRanges');

/**
 * We're prefixing "system" (built-in) custom paramters (like Browser name) with _sys_
 * Currently just used to put these dimensions above the rest in the report settings.
 */
const SYS_START = '_sys_';

const FILTER_FLAG_SUMS = {
	isVideo: 'Is Video',
	isNative: 'Is Native',
	isDeal: 'Is Deal',
	..._.pick(Constants.hb.SUM_DESCRIPTIONS, [
		'renderSuccess',
		'bidWon',
		'unloadBeforeResponse',
		'timedOut',
		'noBid',
		'bidResponse',
		'renderFailed',
		'isDirect',
		'isApproximation',
		'isAdserverBid',
	]),
	isUniqueResponse: 'Unique response',
	adserverWon: 'Adserver won',
};

/**
 * 'cp' in the functions below is short for "custom parameter". Notice that in report settings we're distinguish
 * custom paramers from the rest by prefixing them with '_' although that '_' is not stored in our data.
 * So groupBy: ['_hello'] in the report settings will generate a report for all keys 'hello'.
 */
const cpKey = (name) => `_${name}`;
const cpLabel = (name) => (name.startsWith(SYS_START) ? name.slice(SYS_START.length) : name);
const isCpKey = (name) => name.startsWith('_');

class HbCustomizer extends TypeCustomizer {
	getInitSums() {
		return ['revenue'];
	}

	getExcludedFilterKeys() {
		return [
			'size', // Not supported yet even though it's a dimension
			'mediaType', // TODO: Add support for this in C++ code maybe
		];
	}

	excludeNotAllowed(dimension) {
		return dimension === 'sourceId';
	}

	getDynamicDateRanges() {
		return DynamicDateRanges.getShortTermRanges();
	}

	hasTimezoneSupport() {
		return true;
	}

	async performCall(params, callFn) {
		// Create time slots for the date range
		const timeslots = DateUtils.getTimezoneSlots(params)?.map((slot) => ({ ...params, ...slot })) || [params];
		const [first, ...rest] = await Promise.all(timeslots.map((p) => callFn(p)));
		_.mergeWith(first.data, ...rest.map(({ data }) => data), (dst, src) => (_.isNumber(src) ? (dst || 0) + src : undefined));
		_.merge(first.labels, ...rest.map(({ labels }) => labels));
		return first;
	}

	transformedReportParams(params) {
		const startTs = new Date(params.start).getTime();
		let endTs;
		if (DateUtils.fullDay(params.end).getTime() >= DateUtils.today()) {
			endTs = 0;
		} else {
			endTs = DateUtils.fullDay(params.end, 1).getTime();
		}
		const timeRange = Constants.SHORT_TIME_RANGES[params.granularity];
		return {
			...params,
			startTs,
			endTs,
			timeRangeMs: timeRange ? timeRange.ms : 0,
			includeNonExistingCustomParams: true,
		};
	}

	supportedGranularities() {
		return _.keys(Constants.SHORT_TIME_RANGES).concat(super.supportedGranularities());
	}

	siteSelectType() {
		return 'programmatic';
	}

	normalizeDate(dateStr) {
		return new Date(parseInt(dateStr, 10));
	}

	hasDataForToday() {
		return true;
	}

	getGroupByOptions({ GROUP_BY_OPTIONS }, { hbMeta }) {
		const res = { ...GROUP_BY_OPTIONS };
		EnvReportInterface.MiscUtils.alphaSorted(hbMeta.customParams, 'name').forEach(({ isNumeric, name }) => {
			if (!isNumeric) {
				res[cpKey(name)] = cpLabel(name);
			}
		});
		return res;
	}

	getAdditionalUserGroupByOptions({ groupByOptions }) {
		return Object.keys(groupByOptions || {}).filter(isCpKey); // Include all custom parameters in user reports
	}

	allowAdditionalUserGroupByOption(groupByOption) {
		return isCpKey(groupByOption);
	}
}

Object.assign(HbCustomizer, {
	FILTER_FLAG_SUMS,
	cpKey,
	cpLabel,
});

module.exports = HbCustomizer;
