import React from 'react';
import PropTypes from 'prop-types';

class Disabler extends React.Component {
	constructor(props) {
		super(props);
		this.listener = () => {
			if (!this.elm || !this.props.disabled || this.isRefocusing) {
				return;
			}
			const tmp = document.createElement('input');
			this.elm.appendChild(tmp);
			this.isRefocusing = true;
			const { scrollX, scrollY } = window;
			tmp.focus();
			scrollTo(scrollX, scrollY);
			this.isRefocusing = false;
			this.elm.removeChild(tmp);
		};
		this.elm = null;
	}

	onElement(elm) {
		if (elm === this.elm) {
			return;
		}
		const oldElm = this.elm;
		this.elm = elm;
		if (elm && this.props.disabled) {
			this.startBlocking();
		} else if (!elm && this.started) {
			this.stopBlocking(oldElm);
		}
	}

	onState(disabled) {
		if (disabled) {
			this.startBlocking();
		} else {
			this.stopBlocking();
		}
	}

	stopBlocking(elmOverride) {
		if (!this.started) {
			return;
		}
		const elm = elmOverride || this.elm;
		if (elm) {
			elm.removeEventListener('focus', this.listener, true);
		}
		this.started = false;
	}

	startBlocking() {
		if (this.started || !this.elm) {
			return;
		}
		this.stopBlocking();
		this.elm.addEventListener('focus', this.listener, true);
		this.started = true;
	}

	render() {
		const { disabled, disabledStyle, children } = this.props;
		this.onState(disabled);
		return (
			<div ref={(elm) => this.onElement(elm)} style={disabled ? { position: 'relative' } : {}}>
				{disabled
				&& (
					<div
						style={{
							position: 'absolute',
							left: 0,
							right: 0,
							top: 0,
							bottom: 0,
							background: 'white',
							opacity: 0.5,
							zIndex: 10,
							...disabledStyle,
						}}
					/>
				)}
				{children}
			</div>
		);
	}
}

Disabler.propTypes = {
	disabled: PropTypes.bool.isRequired,
	disabledStyle: PropTypes.object,
};

Disabler.defaultProps = {
	disabled: false,
	disabledStyle: undefined,
};

export default Disabler;
